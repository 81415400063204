<script>
    import Layout from '@layouts/main.vue'
    import Breadcrumb from '@/src/components/Breadcrumb.vue'
    import vuetableFormatters from '@src/mixins/vuetableFormatters'
    import swalFeedback from '@src/mixins/swalFeedback.vue'
    import multiSelectWithService from "@components/multiSelectWithService.vue";
    import {debounce} from "lodash";
    import QuestionnaireItemsService from "@src/services/QuestionnaireItemsService";
    import PageHeader from "@components/PageHeader.vue";
    import Multiselect from 'vue-multiselect'
    import QuestionnaireAnswersService from "@src/services/QuestionnaireAnswersService";
    import FilesService from "@src/services/FilesService";
    import SaveButton from "@components/SaveButton.vue";
    import formatDate from "date-fns/format";
    import parseDate from "date-fns/parse";
    import validation from "@src/mixins/validation.vue";
    import moment from 'moment';
    import UsersService from '@src/services/UsersService'
    import { required } from 'vuelidate/lib/validators'

    const i18nKey = 'WORKSCHEDULES'
    const i18nCommon = 'COMMON'

    const isTrueImage = (value, model) => {
        if (!value || !value.type) {
            return true
        }
        return value.type.startsWith('image')
    }

    const isSizeValid = (value, model) => {
        if (!value || !value.size) {
            return true
        }
        return value.size <= 1048576
    }

    export default {
        components: {
            SaveButton,
            Multiselect,
            PageHeader,
            multiSelectWithService,
            Layout,
            Breadcrumb,
        },
        mixins: [
            validation,
            swalFeedback,
            vuetableFormatters
        ],
        data() {
            return {
                loading: false,
                submitLoading: false,
                sizeLargerThanAllowed: false,
                loadingUser: false,
                data: {
                    photos: null,
                    question: null,
                    questionnaire: null,
                    businessUnit: null,
                    mixes: null,
                    productCategory: null,
                    product: null,
                },
                questions: [],
                questionsLoading: false,
                i18nCommon,
                i18nKey,
                sortOrder: [
                    {
                        field: 'updated_at',
                        direction: 'desc',
                    },
                ],
                modal: {
                    id: null,
                    pos_id: null,
                    pos: {
                        id: 0,
                        name: '',
                    },
                    user_id: null,
                    user: {
                        id: 0,
                        name: '',
                    },
                },
                additionalParametersGrid: {
                    with: ['mix', 'pointOfSale', 'pointOfSale.chain', 'user'],
                    user_id: [this.$route.params.id]
                },
                filtersCalendar: {
                    date: {
                        start: moment().startOf('day').toDate(),
                        end: moment().startOf('day').add(30, 'days').toDate(),
                    },
                    user_id: this.$route.params.id,
                },
                user: {},
            }
        },
        validations:{
            data: {
                question: {
                    required,
                },
                businessUnit: {
                    required,
                },
                questionnaire: {
                    required,
                },
                photos: {
                    isTrueImage,
                    isSizeValid,
                    validateMinFiles(value) {
                        let valid = true;
                        if (this.data.question?.question?.type === "file") {
                            valid = value?.length >= (this.data.question?.question?.min_files ?? 0);
                        }

                        return valid;
                    },
                    validateMaxFiles(value) {
                        let valid = true;
                        if (this.data.question?.question?.type === "file") {
                            valid = value?.length <= (this.data.question?.question?.max_files ?? 0);
                        }

                        return valid;
                    }
                }
            },
        },
        computed: {
            dataValid() {
                return !this.$v.data.$invalid
            },
            filterParameters() {
                return {
                    per_page: 1000,
                    page: 1,
                    orderBy: 'name',
                    sortedBy: 'asc',
                    with: ['mixes'],
                    point_of_sale_id: [parseInt(this.$route.params.pointOfSaleId)],
                }
            },
            questionnaireFilterParameters() {
                return {
                    per_page: 1000,
                    page: 1,
                    orderBy: 'name',
                    sortedBy: 'asc',
                    only_file: true
                }
            },
            filterMixesParameters() {
                return {
                    per_page: 1000,
                    page: 1,
                    orderBy: 'name',
                    search: 'business_unit_id:' + parseInt(this.data.businessUnit?.id ?? 0),
                    searchFields: 'business_unit_id:=',
                }
            },
            i18nAnswer() {
                return this.getI18n(i18nKey,'TITLES.answer')
            },
            i18nBusinessUnit() {
                return this.getI18n(i18nCommon, 'business_unit')
            },
            i18nBack() {
                return this.getI18n(i18nCommon, 'back')
            },
            i18nPhoto() {
                return "Fotos (Min: " + this.data.question.question.min_files + " / Max: " + this.data.question.question.max_files + ")"
            },
            i18nProduct() {
                return this.getI18n('PRODUCTS', 'TITLES.product');
            },
            i18nMixes() {
                return this.getI18n('MIXES', 'TITLES.mix');
            },
            i18nQuestionnaire() {
                return this.getI18n('QUESTIONNAIRES', 'TITLES.questionnaire')
            },
            i18nUsers() {
                return this.getI18nModified({
                    prefix: 'USERS',
                    suffix: 'TITLES.user',
                    modifier: 2,
                })
            },
            i18nWorkSchedule() {
                return this.getI18nModified({
                    prefix: i18nKey,
                    suffix: 'TITLES.work_schedule',
                    modifier: 2,
                })
            },
            items() {
                return [
                    {
                        text: 'Home',
                        href: '/',
                    },
                    {
                        text: this.getI18nModified({
                            prefix: i18nKey,
                            suffix: 'TITLES.work_schedule',
                            modifier: 2,
                        }),
                        active: true,
                    },
                    {
                        text: this.getI18nModified({
                            prefix: 'USERS',
                            suffix: 'TITLES.user',
                            modifier: 2,
                        }),
                        href: '/workschedules'
                    },
                    {
                        text: this.getI18nModified({
                            prefix: i18nKey,
                            suffix: 'TITLES.work_schedule',
                            modifier: 2,
                        }),
                        href: '/workschedule/' + this.$route.params.id
                    },
                    {
                        text: this.getI18n(i18nKey,'TITLES.answer'),
                        active: true,
                    },
                ]
            },
        },
        watch: {
            "data.questionnaire": debounce(function (questionnaire) {
                this.questions = [];
                this.questionsLoading = true;
                this.data.question = null;
                if (this.data.questionnaire) {
                    QuestionnaireItemsService.fetchAll({
                        per_page: 1000,
                        page: 1,
                        orderBy: 'position',
                        sortedBy: 'asc',
                        search: 'questionnaire_id:' + this.data.questionnaire?.id ?? null,
                        searchFields: 'questionnaire_id:=',
                        with: ['question']
                    }).then((response) => {
                        this.questions = response.data.data.filter(item => item.question.type === 'file');
                        this.questionsLoading = false
                    })
                        .catch((err) => {
                            this.questions = [];
                            this.questionsLoading = false
                            return err
                        })
                }
            }),
            filterText: debounce(function (searchText) {
                this.additionalParametersGrid = {
                    search: '',
                    with: ['mix', 'pointOfSale', 'pointOfSale.chain', 'user'],
                }

                if (searchText && searchText.length > 0) {
                    this.additionalParametersGrid.search = `user.name:${searchText};pointOfSale.name:${searchText};mix.name:${searchText}`
                }
                this.refreshTable()
            }, 1000),
        },
        mounted() {
            this.loadingUser = true
            UsersService
            .fetchById(this.$route.params.id)
            .then(response => {
                this.user = response.data.data
            })
            .finally(() => {
                this.loadingUser = false
            })
            this.refreshCalendar(0)
        },
        methods: {
            replaceModalData(data) {
                Object.assign(this.modal, {
                    id: data.id,
                    pos_id: data.point_of_sale_id,
                    pos: data.pointOfSale,
                    user_id: data.user_id,
                    user: data.user,
                    mix_id: data.mix_id,
                    mix: data.mix,
                    start_date: data.start,
                    end_date: data.end,
                    last_start_date: data.start,
                    priority_order: data.priority_order,
                    human_readable_frequencies: data.human_readable_frequency,
                })
            },
            createAdditionalParametersGrid() {
                return this.formatParameters({
                    with: ['mix', 'pointOfSale', 'pointOfSale.chain', 'user'],
                    mix_id: this.filtersGrid.mixes.map((mix) => mix.id),
                    point_of_sale_id: this.filtersGrid.pos.map((pos) => pos.id),
                    frequency: this.filtersGrid.frequency,
                    start: this.formatFilterDate(this.filtersGrid.start),
                    end: this.formatFilterDate(this.filtersGrid.end),
                })
            },
            goToBack() {
                this.$router.push({
                    name: 'workSchedule',
                })
            },
            getFileInvalidMessage() {
                let errorMessage = "invalid_file";

                if (!this.$v.data.photos.validateMinFiles) {
                    errorMessage = 'invalid_min_files'
                } else if (!this.$v.data.photos.validateMaxFiles) {
                    errorMessage = 'invalid_max_files'
                }else if (!this.$v.data.photos.isSizeValid) {
                    errorMessage = 'invalid_size_file'
                }

                return this.$tc(this.makeI18nKey('ERROR_CODES', errorMessage));
            },
            async handleOk() {
                this.$v.data.$touch()

                if (this.submitLoading || !this.dataValid) {
                    return;
                }

                this.submitLoading = true;
                const hash = null; // crypto?.randomUUID() ?? null;
                const now = new Date();
                const questionnaireDate = parseDate(this.$route.params.date, 'yyyy-MM-dd', new Date());
                questionnaireDate.setHours(now.getHours())
                questionnaireDate.setMinutes(now.getMinutes())
                questionnaireDate.setSeconds(now.getSeconds())
                const date = formatDate(questionnaireDate, "yyyy-MM-dd HH:mm:ss");
                const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

                const photos = [];
                for (const photo of this.data.photos) {
                    const result = await FilesService.upload(
                        photo,
                        'answer_photos',
                        false
                    )

                    if (result.data.data.hash ?? null) {
                        photos.push(result.data.data.hash);
                    }
                }
                console.log(photos)

                await QuestionnaireAnswersService.create({
                    questionnaire_answer: {
                        user_id: this.$route.params.id,
                        questionnaire_id: this.data.questionnaire.id,
                        business_unit_id: this.data.businessUnit.id,
                        point_of_sale_id: this.$route.params.pointOfSaleId,
                        product_category_id: this.data.productCategory?.id ?? null,
                        product_id: this.data.product?.id ?? null,
                        latitude: 0,
                        longitude: 0,
                        started_at_date_time: date,
                        started_at_time_zone: timezone,
                        ended_at_date_time: date,
                        ended_at_time_zone: timezone,
                        hash: hash
                    },
                    answer: [
                        {
                            answered_at_date_time: date,
                            answered_at_time_zone: timezone,
                            type: this.data.question.question.type,
                            subtype: this.data.question.question.subtype,
                            question_id: this.data.question.question.id,
                            questionnaire_item_id: this.data.question.id,
                            content: photos,
                        }
                    ]
                }).then(result => {
                    this.successFeedback('Responder Roteiros', 'Questionário respondido com sucesso');
                    this.reset();
                    this.submitLoading = false;
                })
            },
            reset() {
                this.$nextTick(() => this.$v.$reset())
                this.clearAllResponseError()
                this.loading = false;
                this.submitLoading = false;
                this.sizeLargerThanAllowed = false;
                this.data = {
                    photos: null,
                    question: null,
                    questionnaire: null,
                    businessUnit: null,
                    mixes: null,
                    productCategory: null,
                    product: null,
                }
                this.questions = [];
                this.questionsLoading = false;
            },
        },
        metaInfo() {
            return {
                title: this.getI18nModified({
                    prefix: i18nKey,
                    suffix: 'TITLES.work_schedule',
                    modifier: 2,
                }),
            }
        },
    }
</script>
<template>
    <Layout>
        <div class="col-12">
            <Breadcrumb :items="items" />
            <div class="d-flex justify-content-between">
                <b-skeleton v-if="loadingUser" type="button" width="35%" class="mt-1"/>
                <h3 v-else class="mb-0">{{user.name}}</h3>
                <b-button class="btn btn-pdv-gradient-blue-transparent" size="sm" @click="goToBack">
                    <i class="fe-corner-up-left"></i>
                    {{ i18nBack }}
                </b-button>
            </div>

            <PageHeader :title="`${i18nAnswer} ${i18nWorkSchedule}`" />
            <form @submit.prevent="handleOk">
                <b-form-row>
                    <b-col md="12">
                        <b-form-row>
                            <b-col md="4">
                                <b-form-group
                                    class="required label-pdv"
                                    :label="i18nBusinessUnit"
                                    label-for="business-unit"
                                    :invalid-feedback="
                                        getI18n(
                                            'ERROR_CODES.invalid_business_unit'
                                        )
                                    "
                                >
                                    <multiSelectWithService
                                        :id="'business-unit'"
                                        ref="parentBusinessUnitSelect"
                                        v-model="data.businessUnit"
                                        :service="'business-units'"
                                        label="name"
                                        :searchable="true"
                                        :multiple="false"
                                        :parameters="filterParameters"
                                    ></multiSelectWithService>
                                </b-form-group>
                            </b-col>
                        </b-form-row>
                        <b-form-row v-if="data.businessUnit">
                            <b-col md="4">
                                <b-form-group
                                    class="required label-pdv"
                                    :label="i18nQuestionnaire"
                                    label-for="questionnaire"
                                    :invalid-feedback="
                                                getI18n(
                                                    'ERROR_CODES.invalid_business_unit'
                                                )
                                            "
                                >
                                    <multiSelectWithService
                                        :id="'questionnaire'"
                                        ref="questionnaireMultiselect"
                                        v-model="data.questionnaire"
                                        :service="'questionnaires'"
                                        :searchable="true"
                                        :multiple="false"
                                        :parameters="questionnaireFilterParameters"
                                    />
                                </b-form-group>
                            </b-col>
                        </b-form-row>
                        <b-form-row v-if="data.businessUnit && data.questionnaire?.type === 'product'">
                            <b-col md="4">
                                <b-form-group
                                    class="required label-pdv"
                                    :label="i18nMixes"
                                    label-for="mixes"
                                >
                                    <multiSelectWithService
                                        :id="'mixes'"
                                        ref="mixesMultiselect"
                                        v-model="data.mixes"
                                        :service="'mixes'"
                                        :searchable="true"
                                        :multiple="false"
                                        :parameters="filterMixesParameters"
                                    />
                                </b-form-group>
                            </b-col>
                        </b-form-row>
                        <b-form-row  v-if="data.questionnaire?.type === 'product'">
                            <b-col md="4">
                                <b-form-group
                                    class="required label-pdv"
                                    :label="i18nProduct"
                                    label-for="productCategory"
                                    :invalid-feedback="
                                        getI18n(
                                            'ERROR_CODES.invalid_business_unit'
                                        )
                                    "
                                >
                                    <multiSelectWithService
                                        :id="'productCategory'"
                                        ref="productCategoryMultiselect"
                                        v-model="data.product"
                                        :service="'products'"
                                        :searchable="true"
                                        :multiple="false"
                                    />
                                </b-form-group>
                            </b-col>
                        </b-form-row>
                        <b-form-row  v-if="data.questionnaire">
                            <b-col md="4">
                                <b-form-group
                                    class="required label-pdv"
                                    :label="'Questão'"
                                    label-for="question"
                                    :invalid-feedback="
                                        getI18n(
                                            'ERROR_CODES.invalid_business_unit'
                                        )
                                    "
                                >
                                    <Multiselect
                                        :id="'question'"
                                        v-model="data.question"
                                        :options="questions"
                                        :loading="questionsLoading"
                                        track-by="id"
                                        label="label"
                                    />
                                </b-form-group>
                            </b-col>
                        </b-form-row>
                        <b-form-row v-if="data.question">
                            <b-col md="4">
                                <b-form-group
                                    label-for="logoFile"
                                    :label="i18nPhoto"
                                    :invalid-feedback="getFileInvalidMessage()"
                                    class="w-100"
                                >
                                    <b-form-file
                                        ref="logoFile"
                                        v-model="$v.data.photos.$model"
                                        required
                                        type="file"
                                        multiple="multiple"
                                        :min="data.question.question.min_files"
                                        :max="data.question.question.max_files"
                                        accept=".jpg"
                                        :browse-text="getI18n('COMMON', 'browse')"
                                        :placeholder="getI18n('COMMON', 'no_file_chosen')"
                                        :state="validateField('photos', 'data')"
                                        @blur="$v.data.photos.$touch"
                                    />
                                </b-form-group>
                            </b-col>
                        </b-form-row>
                    </b-col>
                </b-form-row>
            </form>

            <save-button
                ref="okButton"
                :busy="submitLoading"
                :disabled="submitLoading || !dataValid"
                :title="getI18n('ACTION.save')"
                @onClick="handleOk"
            />
        </div>
    </Layout>
</template>
